import React from "react";

const RSVPHeader = () => {
  return (
    <div className="rsvp-header">
      <h2 className="rsvp-title">Kindly RSVP</h2>
      <div className="rsvp-date">June 7th, 2025</div>
      <div className="rsvp-date">RSVP Deadline: May 1st, 2025</div>
    </div>
  );
};

export default RSVPHeader;
