import { useState } from "react";

const useGuestManagement = (initialGuestCount = 0) => {
  const [guestCount, setGuestCount] = useState(initialGuestCount);
  const [guests, setGuests] = useState([]);

  const updateGuests = (newCount) => {
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      if (newCount > updatedGuests.length) {
        for (let i = updatedGuests.length; i < newCount; i++) {
          updatedGuests.push({ name: "", isAttending: null });
        }
      } else {
        updatedGuests.length = newCount;
      }
      return updatedGuests;
    });
  };

  const handleGuestCountChange = (input) => {
    // Check if the input is an event object (from onChange)
    const rawValue = typeof input === "object" && input.target ? input.target.value : input;
  
    // Allow empty input for temporary typing
    if (rawValue === "") {
      setGuestCount(""); // Temporarily allow an empty string
      return;
    }
  
    // Only allow integers
    const newValue = rawValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const parsedValue = parseInt(newValue, 10);
  
    if (!isNaN(parsedValue)) {
      const clampedValue = Math.min(parsedValue, 10); // Clamp to 10
      setGuestCount(clampedValue);
      updateGuests(clampedValue);
    }
  };

  const handleGuestCountBlur = () => {
    // If the input is empty, reset to 0 on blur
    const count = parseInt(guestCount, 10) || 0;
    setGuestCount(count);
    updateGuests(count);
  };

  const incrementGuestCount = () => {
    const currentCount = parseInt(guestCount, 10) || 0; // Default to 0 if empty
    const newCount = Math.min(currentCount + 1, 10); // Limit to 10 guests
    setGuestCount(newCount);
    updateGuests(newCount);
  };

  const decrementGuestCount = () => {
    const currentCount = parseInt(guestCount, 10) || 0; // Default to 0 if empty
    const newCount = Math.max(currentCount - 1, 0); // Prevent negative guests
    setGuestCount(newCount);
    updateGuests(newCount);
  };

  // The return statement must be inside the function body
  return {
    guestCount,
    guests,
    setGuests,
    handleGuestCountChange,
    handleGuestCountBlur,
    incrementGuestCount,
    decrementGuestCount,
  };
};

export default useGuestManagement;
