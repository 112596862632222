import React from "react";

const RSVPMessage = ({ message, setMessage }) => {
  return (
    <div className="form-group">
      <label htmlFor="message">Leave a Fun Message or request a song!</label>
      <textarea
        id="message"
        name="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)} // Update message state
        placeholder="Write something fun..."
      />
    </div>
  );
};

export default RSVPMessage;
