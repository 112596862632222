import React from "react";

const GuestCountInput = ({
  guestCount,
  incrementGuestCount,
  decrementGuestCount,
}) => {
  return (
    <div className="form-group">
      <label htmlFor="guestCount">Number of Guests</label>
      <div className="guest-count-container">
        <button
          type="button"
          className="guest-count-btn"
          onClick={decrementGuestCount}
          disabled={guestCount <= 0}
        >
          -
        </button>
        <span className="guest-count-display">{guestCount}</span>
        <button
          type="button"
          className="guest-count-btn"
          onClick={incrementGuestCount}
          disabled={guestCount >= 10}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default GuestCountInput;
