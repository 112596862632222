import { useEffect, useState } from "react";

const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const handleViewportChange = () => {
      if (window.visualViewport) {
        setViewportHeight(window.visualViewport.height);
      } else {
        setViewportHeight(window.innerHeight); // Fallback if visualViewport is not available
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        const currentHeight = window.innerHeight;
        const screenHeight = window.screen.height;

        if (currentHeight < screenHeight * 0.7) {
          const kbHeight = screenHeight - currentHeight;
          setKeyboardHeight(kbHeight);
        } else {
          setKeyboardHeight(0);
        }
      }
    };

    window.visualViewport?.addEventListener("resize", handleViewportChange);
    window.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport?.removeEventListener("resize", handleViewportChange);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { viewportHeight, keyboardHeight };
};

export default useViewportHeight;
