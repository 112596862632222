import { useState } from "react";
import filterGuests from "./filterGuests";

const useGuestSearch = (guestList) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGuests, setFilteredGuests] = useState(guestList);

  const handleSearchChange = (event, guests, setGuests, index) => {
    const value = event.target.value;
    setSearchTerm(value);

    const uniqueFiltered = filterGuests(guestList, value);
    setFilteredGuests(uniqueFiltered);

    const updatedGuests = [...guests];
    updatedGuests[index] = { ...updatedGuests[index], name: value };
    setGuests(updatedGuests);
  };

  const handleGuestSelect = (name, guests, setGuests, index) => {
    const updatedGuests = [...guests];
    updatedGuests[index] = { ...updatedGuests[index], name };
    setGuests(updatedGuests);
    setSearchTerm(""); // Clear search term
  };

  return { searchTerm, filteredGuests, handleSearchChange, handleGuestSelect };
};

export default useGuestSearch;

