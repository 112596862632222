import React, { useState } from "react";

const GuestDetails = ({
  guests,
  searchTerm,
  filteredGuests,
  activeGuestIndex,
  handleSearchChange,
  handleSearchFocus,
  handleGuestSelect,
  handleGuestChange,
  handleKeyPress,
}) => {
  const [animationIndex, setAnimationIndex] = useState(null); // Track which guest's animation is active

  const handleYesClick = (index) => {
    setAnimationIndex(index); // Trigger animation for this guest
    setTimeout(() => setAnimationIndex(null), 1000); // Remove animation after 1 second
  };

  return (
    <>
      {guests.map((guest, index) => (
        <div key={index} className="form-group guest-details">
          <h4>Guest {index + 1}</h4>

          {/* Guest search container */}
          <div className="guest-search-container">
            <input
              type="text"
              name="name"
              placeholder="Search guest name"
              value={guest.name}
              onChange={(e) => handleSearchChange(e, index)}
              onFocus={(e) => handleSearchFocus(e, index)}
              onKeyPress={handleKeyPress}
            />

            {/* Display filtered guest names only for the active input */}
            {activeGuestIndex === index && searchTerm && (
              <ul className="guest-dropdown">
                {filteredGuests.map((guestName) => (
                  <li
                    key={guestName}
                    onClick={() => handleGuestSelect(guestName, index)}
                  >
                    {guestName}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Message for checkboxes */}
          <p className="checkbox-header">
            Will this guest be attending the wedding?
          </p>

          {/* Horizontal alignment of checkboxes */}
          <div className="checkbox-group horizontal">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                name="isAttending"
                checked={guest.isAttending === true}
                onChange={() => {
                  handleGuestChange(index, {
                    target: { name: "isAttending", value: true },
                  });
                  handleYesClick(index); // Trigger animation
                }}
              />
              <span className="custom-checkmark">
                {/* Burst animation */}
                {animationIndex === index && (
                  <div className="burst-animation">
                    {[...Array(6)].map((_, i) => (
                      <span
                        key={i}
                        className={`burst ${
                          Math.random() > 0.5 ? "heart" : "star"
                        } direction-${i + 1}`}
                      ></span>
                    ))}
                  </div>
                )}
              </span>
              Yes
            </label>

            <label className="custom-checkbox">
              <input
                type="checkbox"
                name="isAttending"
                checked={guest.isAttending === false}
                onChange={() =>
                  handleGuestChange(index, {
                    target: { name: "isAttending", value: false },
                  })
                }
              />
              <span className="custom-checkmark"></span>
              No
            </label>
          </div>
        </div>
      ))}
    </>
  );
};

export default GuestDetails;
