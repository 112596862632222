import { useState } from "react";

const useRSVPSubmit = (guestList) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ guests, message }) => {
    // Clear previous messages
    setErrorMessage("");
    setSuccessMessage(null);

    // Check if the guest list is loaded
    if (!guestList || guestList.length === 0) {
      setErrorMessage("Guest list is not available. Please try again later.");
      return;
    }

    // Validate guests
    if (!Array.isArray(guests) || guests.length === 0) {
      setErrorMessage("You must RSVP for at least one guest.");
      return;
    }

    const invalidGuests = guests
      .map((guest, index) => ({
        name: guest.name?.trim() || `Guest ${index + 1}`,
        isValid: guestList.includes(guest.name?.trim()),
      }))
      .filter((guest) => !guest.isValid);

    if (invalidGuests.length > 0) {
      const invalidGuestNames = invalidGuests.map((guest) => guest.name);
      setErrorMessage(
        `The following guests are not on the guest list: ${invalidGuestNames.join(", ")}.`
      );
      return;
    }

    // Set loading state
    setLoading(true);

    try {
        const response = await fetch('https://rsvpsite-gv12.onrender.com/api/rsvp', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ guests, message }),
      });

      if (!response.ok) {
        try {
          const errorData = await response.json();
          setErrorMessage(errorData.message || "There was an error submitting your RSVP.");
        } catch (e) {
          setErrorMessage("A server error occurred. Please try again later.");
        }
        return;
      }

      const responseData = await response.json();

      setSuccessMessage({
        addedGuests: (responseData.addedGuests || []).map((guest) => ({
          name: guest.name || "Unnamed Guest",
          attending: guest.attending,
        })),
        updatedGuests: (responseData.updatedGuests || []).map((guest) => ({
          name: guest.name || "Unnamed Guest",
          attending: guest.attending,
        })),
        skippedGuests: (responseData.skippedGuests || []).map((guest) => ({
          name: guest.name || "Unnamed Guest",
          attending: guest.attending,
        })),
      });
    } catch (error) {
      console.error("Error submitting RSVP:", error);
      setErrorMessage(
        error.message.includes("NetworkError")
          ? "Network error occurred. Please check your connection and try again."
          : "An error occurred while processing your RSVP."
      );
    } finally {
      setLoading(false);
    }
  };

  return { handleSubmit, errorMessage, successMessage, loading };
};

export default useRSVPSubmit;
