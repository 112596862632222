import "../styles/RSVPStatus.css";
import React from "react";

const RSVPStatus = ({ errorMessage, successMessage, loading }) => {

  return (
    <div className="rsvp-status">
      {loading && <p className="loading-message">Submitting your RSVP...</p>}
      {errorMessage && (
        <div className="status-message error">
          <p>{errorMessage}</p>
        </div>
      )}
      {successMessage && (
        <div className="status-message success">
          <h4>RSVP Submitted Successfully</h4>
          {successMessage.addedGuests?.length > 0 && (
            <div>
              <h5>Added Guests:</h5>
              <ul>
                {successMessage.addedGuests.map((guest, index) => (
                  <li key={index}>
                    🎉 {guest.name}: {guest.attending ? "Attending" : "Not Attending"}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {successMessage.updatedGuests?.length > 0 && (
            <div>
              <h5>Updated Guests:</h5>
              <ul>
                {successMessage.updatedGuests.map((guest, index) => (
                  <li key={index}>
                    🔄 {guest.name}: {guest.attending ? "Attending" : "Not Attending"}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {successMessage.skippedGuests?.length > 0 && (
            <div>
              <h5>Skipped Guests (No Changes):</h5>
              <ul>
                {successMessage.skippedGuests.map((guest, index) => (
                  <li key={index}>
                    ✔️ {guest.name}: {guest.attending ? "Attending" : "Not Attending"}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RSVPStatus;
