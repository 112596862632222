import React from "react";

const WeddingDetails = () => {
  return (
    <div>
      <div className="wedding-details">
        <h3>Wedding Details</h3>
        <p><strong>Venue:</strong> The Blair Conference Center</p>
        <p><strong>Address:</strong> 9079 Leroy Rd, Westfield Center, OH 44251</p>
        <br />
        <p><strong>Ceremony Time:</strong> 4:30 PM</p>
        <p><strong>Reception Time:</strong> 5:30 PM</p>
        <br />
        <p>
          <strong>Dress Code:</strong> Semi-Formal attire, no jeans, colorful
          attire is encouraged!
        </p>
        <br />
        <p>
          <strong>Accommodations:</strong> A block of rooms has been reserved at
          The Westfield Inn. To book a room in the block, give the Westfield Inn
          a call at (330) 887-6701 and let them know you're there for the
          Shearer-Cole wedding.
        </p>
      </div>

      <div className="registry-link">
        <a
          href="https://www.amazon.com/wedding/share/morganandjordan2025"
          target="_blank"
          rel="noopener noreferrer"
          title="Visit our wedding registry on Amazon"
          aria-label="View our registry on Amazon"
        >
          View Our Registry
        </a>
      </div>
    </div>
  );
};

export default WeddingDetails;
