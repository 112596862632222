import React from 'react';
import '../styles/OurStory.css';  // We'll style this component in a separate CSS file
import Countdown from './countdown.js';

const OurStory = () => {
  return (
    <section className="our-story">
      <div className="story-content">
        <div className="story-image">
          <img src="/images/our-story.png" alt="Our Story" loading="lazy" />
        </div>
        <div className="story-text">
          <h2>Our Story</h2>
          <p>
            Our story really starts with the Homecoming dance our senior year. We had both gone without dates and early on Jordan asked Morgan to dance and we spent the whole night dancing and having a great time.
          </p>
          <p>
            After Homecoming we talked more and more until we finally started dating on February 27th, 2019. We were lucky enough to be there for each other throughout college and made long(ish) distance work.
          </p>
          <p>
            While we were figuring out the real world we ended up buying a home in mid 2023 and when we moved in Jordan proposed on August 26th, 2023.
          </p>
          <p>
            Join us as we continue writing our story, surrounded by the people we cherish most.
          </p>
        </div>
      </div>
      <Countdown />
    </section>
  );
};

export default OurStory;
