import React from "react";

const SubmitButton = ({ loading, handleSubmit }) => {
  return (
    <button type="submit" className="submit-btn" disabled={loading}>
      {loading ? "Submitting..." : "Submit RSVP"}
    </button>
  );
};

export default SubmitButton;
