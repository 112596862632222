import React, { useState, useEffect } from 'react';
import '../styles/RSVPForm.css';
import guestList from './guestList.js';
import GuestDetails from "./GuestDetails";
import WeddingDetails from "./WeddingDetails";
import RSVPHeader from "./RSVPHeader";
import RSVPMessage from "./RSVPMessage";
import RSVPStatus from "./RSVPStatus";
import GuestCountInput from "./GuestCountInput";
import useViewportHeight from "./useViewportHeight";
import filterGuests from "./filterGuests";
import SubmitButton from "./SubmitButton";
import useGuestManagement from "./useGuestManagement";
import useRSVPSubmit from "./useRSVPSubmit";
import useGuestSearch from "./useGuestSearch";

const RSVPForm = () => {
  const [isAttending, setIsAttending] = useState(null); // Initial state is null
  const [activeGuestIndex, setActiveGuestIndex] = useState(null); // Track active input
  const [message, setMessage] = useState(""); // New message state
  const { viewportHeight, keyboardHeight } = useViewportHeight();
  const { handleSubmit, errorMessage, successMessage, loading } = useRSVPSubmit(guestList);
  const { searchTerm, filteredGuests, handleSearchChange, handleGuestSelect } =
  useGuestSearch(guestList);
  const {
    guestCount,
    guests,
    setGuests,
    handleGuestCountChange,
    handleGuestCountBlur,
    incrementGuestCount, // Ensure this is destructured
    decrementGuestCount, // Ensure this is destructured
  } = useGuestManagement();

  const handleSearchFocus = (event, index) => {
    const inputField = event.target;
    setActiveGuestIndex(index); // Track which input is focused
  
    const dropdown = document.querySelector('.guest-dropdown');
    
    if (dropdown && window.innerWidth > 768 && window.innerWidth <= 1024) {
      // Special adjustment for iPad landscape
      inputField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      dropdown.style.top = `${inputField.getBoundingClientRect().bottom + window.scrollY}px`;
    } else if (dropdown && window.innerWidth <= 768) {
      // Mobile adjustments
      inputField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleGuestChange = (index, event) => {
    const updatedGuests = guests.map((guest, i) =>
      i === index
        ? { ...guest, [event.target.name]: event.target.value }
        : guest
    );
    setGuests(updatedGuests);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({ guests, message });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent form submission
      event.target.blur();  // Remove focus from the input
    }
  };

  return (
    <form className="rsvp-form" onSubmit={onSubmit}>
      {/* RSVP Section Title */}
      <RSVPHeader />

      <WeddingDetails />

      <GuestCountInput
        guestCount={guestCount}
        handleGuestCountChange={handleGuestCountChange}
        handleGuestCountBlur={handleGuestCountBlur}
        incrementGuestCount={incrementGuestCount} // Pass the increment function
        decrementGuestCount={decrementGuestCount} // Pass the decrement function
      />

      {guestCount > 0 && (
        <GuestDetails
          guests={guests}
          searchTerm={searchTerm}
          filteredGuests={filteredGuests}
          handleSearchChange={(e, i) => handleSearchChange(e, guests, setGuests, i)}
          handleGuestSelect={(name, i) => handleGuestSelect(name, guests, setGuests, i)}
          activeGuestIndex={activeGuestIndex}
          handleSearchFocus={handleSearchFocus}
          handleGuestChange={handleGuestChange}
          handleKeyPress={handleKeyPress}
        />
      )}


      {/* Message field */}
      <RSVPMessage message={message} setMessage={setMessage} />

      {/* Submit Button */}
      <RSVPStatus
        errorMessage={errorMessage}
        successMessage={successMessage} // Structured object
        loading={loading}
      />
      <SubmitButton loading={loading} />
    </form>
  );
};


export default RSVPForm;
